import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { BillingService } from '../../../services/billing/billing.service'

@Component({
  selector: 'app-billing-address-overlay',
  template: `
    <pc-overlay [config]="billing.billingAddressDialog" (closeClick)="closeClick.emit()">
      <ng-container body>
        @for (field of billing.billingFormCompanyFields; track field) {
          @if (field.type === 'text') {
            <pc-input-textfield
              [control]="field.control"
              [placeholder]="field.placeholder"
              variant="fit-width"
              [errorHint]="field.control.valid ? '' : field?.errorHint">
            </pc-input-textfield>
          }
          @if (field.type === 'select') {
            <pc-input-select [form]="billing.billingAddressForm" [config]="field"></pc-input-select>
          }
        }

        <div style="margin: 0px; width: 100%;">{{ 'USR_SETTINGS.MANDATORY' | translate }}</div>
      </ng-container>

      <flex-row-end buttons style="justify-content: flex-end;">
        <pc-button
          [disabled]="!billing.billingAddressForm?.valid"
          size="small"
          [text]="billing.billingAccount ? ('USR_SETTINGS.UPDATE_BILLING_ACCOUNT' | translate) : ('USR_SETTINGS.CREATE_BILLING_ACCOUNT' | translate)"
          (btnClick)="submitClick.emit()"></pc-button>
      </flex-row-end>
    </pc-overlay>
  `,
  styleUrls: ['./billing-address-overlay.component.scss'],
})
export class BillingAddressOverlayComponent implements OnInit {
  @Output() closeClick = new EventEmitter()

  @Output() submitClick = new EventEmitter()

  constructor(public billing: BillingService) {}

  ngOnInit(): void {
    // Fire this off again to clear any values which had been entered but not saved.
    this.billing.createBillingAddressForm()
  }
}
